import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {HammerModule} from '@angular/platform-browser';
import {NotificationModule} from '@progress/kendo-angular-notification';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {AuthModule} from 'angular-auth-oidc-client';
import {environment} from '@environments/environment';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {BasicAuthInterceptor} from '@app/core/interceptors/basic-auth.interceptor';
import {AuthFacade} from '@app/core/facades/auth.facade';
import {ErrorInterceptor} from '@app/core/interceptors/error.interceptor';
import {APP_CONFIG_OBJ, AppConfig} from '@app/core/config/app.config';
import {provideUserIdleConfig} from 'angular-user-idle';
import { NotifyService } from '@app/shared/services/notify.service';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(HammerModule),
    importProvidersFrom(NotificationModule),
    importProvidersFrom(DialogModule),
    importProvidersFrom(AuthModule.forRoot({
      config: {
        authority: `${environment.apiUrl}/oidc`,
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: window.location.origin, //'${environment.apiUrl}/oidc/logout',
        clientId: 'public-client',
        scope: 'openid portfolio.read portfolio.write', // 'openid profile ' + your scopes
        responseType: 'code',
        silentRenew: false,
        silentRenewUrl: window.location.origin + '/silent-renew.html',
        renewTimeBeforeTokenExpiresInSeconds: 10,
        autoUserInfo: false
      }
    })),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })),
    provideUserIdleConfig({ idle: environment.userIdle, timeout: environment.userTimeout, ping: 120 }),
    {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true, deps: [AuthFacade]},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [AuthFacade, NotifyService]},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: new Date()?.toString()?.match(/([-\+][0-9]+)\s/)![1] }},
    {provide: AppConfig, useValue: APP_CONFIG_OBJ},
  ]
};
