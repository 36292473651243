<div class="container">
    {{'CORE.DIALOG.TIMEOUT_LOGOUT_QUESTION' | translate}}
</div>
<kendo-dialog-actions>
    <button (click)="onCancelAction()" kendoButton>{{'CORE.DIALOG.LOG_OFF' | translate}}</button>
    <button
            (click)="onConfirmAction()"
            kendoButton
            themeColor="primary"
    >
        {{'CORE.DIALOG.STAY_LOGGED_IN' | translate}} ({{timer$ | async}})
    </button>
</kendo-dialog-actions>
