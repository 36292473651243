import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthFacade} from "@app/core/facades/auth.facade";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private _router: Router,
    private _authFacade: AuthFacade
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Check if user is logged in
    const isLoggedIn = this._authFacade.isLoggedIn;
    if (isLoggedIn) {
      return true;
    }
    return false;
  }

}
