/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from '@app/app.config';
import {AppComponent} from '@app/app.component';
import {environment} from '@environments/environment';
import {enableProdMode} from '@angular/core';
import {AppInjector} from '@app/core/utils/app-injector';


if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .then(ref => {
    AppInjector.setInjector(ref.injector);
  })
  .catch(err => console.error(err));
