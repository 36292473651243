import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainLoaderService} from "@app/shared/services/main-loader.service";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-forbidden-page',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss']
})
export class ForbiddenPageComponent {
  constructor(
    private _mainLoaderService: MainLoaderService
  ) {
    this._mainLoaderService.hideLoader();
  }

}
