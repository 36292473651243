import {inject, Injectable} from '@angular/core';
import {CoreUrlProvider} from '@core/config/core-url.provider';
import {AuthFacade} from '@core/facades/auth.facade';
import {EventSourceMessage, fetchEventSource} from '@microsoft/fetch-event-source';
import {environment} from '@environments/environment';
import {NotifyService} from '@shared/services/notify.service';

@Injectable({
  providedIn: 'root'
})
export class SseService {
  private _authFacade: AuthFacade = inject(AuthFacade);
  private _notifyService: NotifyService = inject(NotifyService);

  public async connect(): Promise<void> {
    const accessToken = this._authFacade.accessToken;
    await fetchEventSource(`${environment.apiUrl}${CoreUrlProvider.URL.SSE.BASE}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/octet-stream'
      },
      onmessage: (ev: EventSourceMessage) => {
        const event = JSON.parse(ev.data);
        this._notifyService.showNotification({type: event.type, messageRaw: event.message})
      }
    });
  }
}
