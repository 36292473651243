<div class="logo-details">
  <div class="logo-img pointer" [routerLink]="['/']"></div>
</div>
<div class="menu-details">
  <i class="ico-menu-1" id="btn" (click)="onCloseBtnClick()"></i>
</div>
<ul class="nav-list main">
  <ng-container *ngIf="user">
    <ng-container *ngFor="let item of menu">
      <ng-container *ngIf="item.url">
        <ng-container *ngTemplateOutlet="itemTemplate, context: {$implicit: item}"></ng-container>
      </ng-container>
      <li *ngIf="item?.children">
        <a class="pointer" (click)="item['show'] = !item['show']">
          <i class="{{item?.icon}}"
             kendoTooltip
             title="{{item.name | translate}}"></i>
          <span class="links_name">{{item.name | translate}}</span>
        </a>
        <span class="tooltip">{{item.name | translate}}</span>
        <ul class="nav-list sub" *ngIf="item['show']">
          <ng-container *ngFor="let subItem of item?.children">
            <ng-container *ngTemplateOutlet="itemTemplate, context: {$implicit: subItem}"></ng-container>
          </ng-container>
        </ul>
      </li>
    </ng-container>
    <ng-template #itemTemplate let-item>
      <li [canDoAction]="item?.permissions">
        <a [routerLink]="[item?.url]"
           [routerLinkActive]="'active'">
          <i class="{{item?.icon}}"
             kendoTooltip
             title="{{item?.name | translate}}"></i>
          <span class="links_name">{{item?.name | translate}}</span>
        </a>
        <span class="tooltip">{{item.name | translate}}</span>
      </li>
    </ng-template>
  </ng-container>
</ul>
<ul class="nav-list bottom">
  <li class="profile-avatar" [routerLink]="['/profile']">
    <kendo-avatar themeColor="none" [width]="'44px'" [height]="'44px'"
                  [initials]="(user?.username + ' ' + user?.lastName) | initials" class="pointer"></kendo-avatar>
  </li>
  <li class="profile"
      title="{{ 'RELEASE_NOTES.TITLE' | translate }}"
      [routerLink]="['/release-notes']">
    <i class="ico-question-help-circle"></i>
  </li>
  <li class="profile"
      title="{{ 'CORE.COMMON.LOG_OUT' | translate }}">
    <div class="profile-details pointer" [routerLink]="['/profile']">
      <kendo-avatar themeColor="none" [width]="'44px'" [height]="'44px'"
                    [initials]="(user?.username + ' ' + user?.lastName) | initials" class="pointer"></kendo-avatar>
      <div class="name-details">
        <div class="first-line">{{user?.username}}</div>
        <div class="second-line">{{user?.email}}</div>
      </div>
    </div>
    <i class="ico-logout pointer" id="log_out" (click)="logout()"></i>
  </li>
</ul>
