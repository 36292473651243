import {shadeColor} from "@app/core/utils/utils";

export class AppConfig {
  languages = {
    available: ['en'],
    default: 'en'
  };
  period = {
    type: 'year',
    yearStartDay: 1,
    yearStartMonth: 4,
  };
  charts = {
    series: {
      colors: generateColors([
        '#75a584',
        '#e88733',
        '#457FA0'
      ])
    }
  }
}

function generateColors(mainColors: string[]): string[] {
  const colors: string[] = [];
  let i = 0;
  while (i < 200) {
    mainColors.forEach(color => {
      colors.push(shadeColor(color, i));
    });
    i += 50;
  }
  return colors;
}

export const APP_CONFIG_OBJ: AppConfig = new AppConfig();
