import {CanMatchFn, Router, UrlTree} from '@angular/router';
import {AuthFacade} from '@app/core/facades/auth.facade';
import {inject} from '@angular/core';
import {filter, iif, map, Observable, of, switchMap, tap} from 'rxjs';
import {User} from '@app/core/models/user';

export const passwordChangeRequiredGuard: CanMatchFn = (route, state): Observable<boolean | UrlTree> => {
  if (state?.toString().includes('change-password')) {
    return of(true);
  }
  const _authFacade: AuthFacade = inject(AuthFacade);
  const _router: Router = inject(Router);
  return _authFacade.user$.pipe(
    filter(Boolean),
    map((user: User|null) => !user?.passwordChangingRequired),
    switchMap((value: boolean) => iif(() => value, of(true), of(_router.parseUrl('/change-password'))))
  );
};
