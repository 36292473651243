import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "@environments/environment";
import {AuthFacade} from "@app/core/facades/auth.facade";

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

  constructor(
    private _authFacade: AuthFacade
  ) {
  }

  // Add basic auth header with jwt token if user is logged in and request is to the api url
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add header with basic auth credentials if user is logged in and request is to the api url
    const accessToken = this._authFacade.accessToken;
    const isLoggedIn = this._authFacade.isLoggedIn;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }

    return next.handle(request);
  }
}
