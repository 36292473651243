import { Injector } from '@angular/core';

export class AppInjector {
  private static injector: Injector;

  /***
   * Set injector
   * @param injector
   */
  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  /***
   * Get injector
   */
  static getInjector(): Injector {
    return AppInjector.injector;
  }

}
