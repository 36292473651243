import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MainLoaderService {
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public loading$ = this._loadingSubject.asObservable();
  constructor() { }

  public show(value: boolean) {
    this._loadingSubject.next(value);
  }
  public showLoader() {
    this._loadingSubject.next(true);
  }

  public hideLoader() {
    this._loadingSubject.next(false);
  }
}
