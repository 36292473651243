import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterOutlet} from "@angular/router";
import {SidebarComponent} from "@app/shell/components/sidebar/sidebar.component";
import {ContentComponent} from "@app/shell/components/content/content.component";
import {MainLoaderService} from "@app/shared/services/main-loader.service";

@Component({
  selector: 'app-shell',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SidebarComponent, ContentComponent],
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShellComponent implements OnInit {

  constructor(
    private _mainLoaderService: MainLoaderService
  ) {
  }

  ngOnInit(): void {
    this._mainLoaderService.hideLoader();
  }

}
