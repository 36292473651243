import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {AuthFacade} from "@app/core/facades/auth.facade";
import { NotifyService } from '@app/shared/services/notify.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _authFacade: AuthFacade,
    private _notifyService: NotifyService
  ) {
  }

  // if error status is 401 or 403 - redirect to login page
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403].includes(err.status)) {
        this._authFacade.login();
      }
      this._errorHandler(err);
      const error = err.error.message || err.statusText;
      return throwError(() => error);
    }))
  }

  // handle error message
  private _errorHandler(error: HttpErrorResponse): void {
    // console.log(error);
    let errorMsg: string = '';
    switch (error.status) {
      case 400:
        errorMsg = `Bad Request: \n\r ${error.error?.detail ?? error.error?.path}`;
        break;
      case 500:
        errorMsg = `Server error: \n\r ${error.error?.path}`;
        break;
      default:
          errorMsg = `Error: ${error.error.detail || error.statusText}`;
          break;
    }
    this._showNotification(errorMsg);
  }

  // display notification with error message
  private _showNotification(error: string): void {
    this._notifyService.showNotification({type: 'error', messageRaw: error});
  }
}
