import {Injectable} from "@angular/core";
import {Alert} from "@app/core/models/alert";
import {Observable} from "rxjs";
import {AlertsStore} from "@app/core/stores/alerts.store";

@Injectable({
  providedIn: 'root'
})
export class AlertsFacade {

  /***
   * Alerts list observables
   */
  public alerts$: Observable<Alert[]|null> = this._alertsStore.alerts$;

  /***
   * Alerts loading observables
   */
  public loading$: Observable<boolean> = this._alertsStore.loading$;
  /***
   * Alerts error observables
   */
  public error$: Observable<boolean> = this._alertsStore.error$;

  constructor(
    private _alertsStore: AlertsStore
  ) { }

  /***
   * Get alerts
   */
  public getAlerts() {
    this._alertsStore.getAlerts();
  }

  /***
   * Clear alerts
   */
  public clearAlerts() {
    this._alertsStore.clearAlerts();
  }
}
