import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Alert} from "@app/core/models/alert";
import {CoreUrlProvider} from "@app/core/config/core-url.provider";
import {ApiHttpService} from "@app/core/services/api-http.service";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private _http: ApiHttpService
  ) {
  }

  /***
   * Get all alerts
   */
  public getAlerts(): Observable<Alert[]> {
    return this._http.get(CoreUrlProvider.URL.ALERTS.BASE);
  }
}
