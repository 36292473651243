import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainLoaderService} from "@app/shared/services/main-loader.service";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent {
  constructor(
    private _mainLoaderService: MainLoaderService
  ) {
    this._mainLoaderService.hideLoader();
  }

}
