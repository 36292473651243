import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationCancel, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { delay, filter, Observable, Subscription } from 'rxjs';
import { AppConfig } from '@app/core/config/app.config';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthFacade } from '@app/core/facades/auth.facade';
import { MainLoaderService } from '@app/shared/services/main-loader.service';
import { UserFacade } from '@app/core/facades/user.facade';
import { DataHelper } from '@app/core/helpers/data.helper';
import { UserData } from '@app/core/models/user-data';
import { LoginResponse } from 'angular-auth-oidc-client/lib/login/login-response';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { HammerModule } from '@angular/platform-browser';
import { IdleService } from '@app/core/services/idle.service';
import { SseService } from '@shared/services/sse.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, TranslateModule, HammerModule, RouterOutlet, LoaderComponent, DialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnDestroy {
  public isAuthenticated: boolean = false;
  public isLoading$: Observable<boolean> = this._mainLoaderService.loading$.pipe(delay(10));
  private _subscription: Subscription = new Subscription();
  private _lastRoute: string = '';

  constructor(
    private _appConfig: AppConfig,
    private _translateService: TranslateService,
    private _authFacade: AuthFacade,
    private _router: Router,
    private _mainLoaderService: MainLoaderService,
    private _userFacade: UserFacade,
    private _idleService: IdleService,
    private _sseService: SseService,
  ) {
    this._translateService.setDefaultLang(this._appConfig.languages.default);
    this._init();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._idleService.onDestroy();
  }

  private _init() {
    if (document.getElementById('body')) {
      document.getElementById('body')?.setAttribute('id', 'loading');
    }
    this._idleService.init();

    // Check if user is authenticated in OIDC
    this._subscription.add(this._authFacade.checkAuth$.subscribe({
      next: (data: LoginResponse) => {
        this.isAuthenticated = data?.isAuthenticated;
        if (!data?.isAuthenticated) {
          // Redirect to login page
          this._authFacade.login();
        } else {
          const userData: UserData = {
            isAuthenticated: data.isAuthenticated,
            accessToken: data.accessToken,
          };
          this._authFacade.setUserData(userData);
          this._authFacade.getCurrentUser();
          this._sseService.connect();
          this._router.navigate([window.location.pathname], { onSameUrlNavigation: 'reload' });
        }

      },
      error: err => this._authFacade.login(),
    }));

    // Check if page is loading
    this._subscription.add(this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: any) => {
        if (event?.urlAfterRedirects?.length > 1 && !event?.urlAfterRedirects?.includes('/?code=')) {
          if (document.getElementById('loading')) {
            document.getElementById('loading')?.setAttribute('id', 'body');
          }
        }
      }));
    // Check if page is loading
    this._subscription.add(this._router.events
      .pipe(
        filter((event) => event instanceof NavigationCancel),
      )
      .subscribe((event: any) => {
        // Save last route
        if (!this._userFacade.hasUserActions && event?.url?.length > 1 && !event?.url?.includes('/?code=')) {
          this._lastRoute = event?.url;
        }
      }));

    // Navigate to last route after user actions are loaded
    this._subscription.add(this._userFacade.hasUserActions$.subscribe(() => {
      if (DataHelper.isNotEmpty(this._lastRoute)) {
        this._router.navigateByUrl(this._lastRoute);
        this._lastRoute = '';
      }
    }));
  }
}
