import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DialogContentBase, DialogModule, DialogRef} from '@progress/kendo-angular-dialog';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {Observable, Subscription} from 'rxjs';
import {IdleService} from '@app/core/services/idle.service';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'app-timeout-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    DialogModule,
    ButtonModule,
    AsyncPipe
  ],
  templateUrl: './timeout-dialog.component.html',
  styleUrl: './timeout-dialog.component.scss'
})
export class TimeoutDialogComponent extends DialogContentBase implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  public timer$: Observable<number>| undefined;

  constructor(
    public override dialog: DialogRef,
    private _idleService: IdleService,
  ) {
    super(dialog);
    this.timer$ = this._idleService.timeout$;
  }

  public ngOnInit(): void {

  }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public onCancelAction(): void {
    this.dialog.close({ value: false  });
  }

  public onConfirmAction(): void {
    this.dialog.close({ value: true });
  }
}
