import {Injectable, OnDestroy} from "@angular/core";
import {AlertsService} from "@app/core/services/alerts.service";
import {Alert} from "@app/core/models/alert";
import {filter, first, Observable, Subject, tap} from "rxjs";
import {Store} from "@app/core/utils/store";

@Injectable({
  providedIn: 'root'
})
export class AlertsStore implements OnDestroy {
  private _unsubscriber: Subject<void> = new Subject<void>()

  private _store = new Store<{
    loading: boolean
    error: any,
    alerts: Alert[] | null
  }>(
    {
      loading: false,
      error: null,
      alerts: null,
    },
    this._unsubscriber,
  );

  public alerts$: Observable<Alert[]|null> = this._store.select('alerts');
  public loading$: Observable<boolean> = this._store.select('loading');
  public error$: Observable<boolean> = this._store.select('error').pipe(filter(value => !!value));

  constructor(
    private _alertsService: AlertsService
  ) {
  }

  public ngOnDestroy(): void {
    this._unsubscriber.next()
    this._unsubscriber.complete()
  }

  /***
   * Get alert list and set it to store
   */
  public getAlerts() {
    this._alertsService.getAlerts()
      .pipe(
        tap(() => this._store.setState({ loading: true })),
        first()
      )
      .subscribe({
        next: (data: Alert[]) => {
          this._store.setState({
            alerts: data,
            loading: false
          })
        },
        error: (error) => {
          this._store.setState({
            alerts: [],
            loading: false,
            error: error
          })
        }
      })
  }

  /***
   * Clear alerts store
   */
  public clearAlerts() {
    this._store.setState({alerts: []});
  }
}
