import {Injectable} from '@angular/core';
import {UserIdleService} from 'angular-user-idle';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {BehaviorSubject, filter, Observable, Subscription, take} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuthFacade} from '@app/core/facades/auth.facade';
import {TimeoutDialogComponent} from '@app/shared/components/timeout-dialog/timeout-dialog.component';
import {environment} from '@environments/environment';
import {DataHelper} from '@app/core/helpers/data.helper';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private _subscription: Subscription = new Subscription();
  private _confirmDialogOpen: boolean = false;
  private _subjectTimeout: BehaviorSubject<number> = new BehaviorSubject<number>(environment.userTimeout);

  public timeout$: Observable<number> = this._subjectTimeout.asObservable();

  constructor(
      private _userIdle: UserIdleService,
      private _dialogService: DialogService,
      private _translateService: TranslateService,
      private _authFacade: AuthFacade
  ) {}

  public onDestroy() {
    this._subscription.unsubscribe();
  }

  public init() {
    this._handleInactiveUser();
    this._subscription.add(this._authFacade.isAuthenticated$.pipe(filter(Boolean)).subscribe((value) => {
      this.startWatching();
    }));
  }

    public startWatching() {
    this._subjectTimeout.next(environment.userTimeout);
    this._userIdle.startWatching();
  }

  private _handleInactiveUser() {
    // Start watching when user idle is starting.
    this._subscription.add(this._userIdle.onTimerStart().pipe(
        filter((value: any) => DataHelper.hasValue(value))
    ).subscribe((count: number) => {
      this._subjectTimeout.next(environment.userTimeout  - count);
      if (!this._confirmDialogOpen) {
        this._confirmLogout();
      }
    }));

    // Start watch when time is up.
    this._subscription.add(this._userIdle.onTimeout().subscribe(() => {
      this._userIdle.stopWatching();
      this._authFacade.logout();
    }));
  }

  private _confirmLogout() {
    this._confirmDialogOpen = true;
    const dialog: DialogRef = this._dialogService.open({
      title: this._translateService.instant('CORE.DIALOG.SESSION_TIMEOUT'),
      content: TimeoutDialogComponent,
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result
      .pipe(take(1))
      .subscribe((result: any) => {
        this._confirmDialogOpen = false;
        if (!result?.value) {
          this._authFacade.logout();
        } else {
          this._userIdle.resetTimer();
        }
      });
  }
}
