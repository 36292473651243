import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {filter, map, Observable, of} from 'rxjs';
import {AuthFacade} from "@app/core/facades/auth.facade";
import {User} from "@app/core/models/user";

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  constructor(
    private _authFacade: AuthFacade,
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    if (this._authFacade.user) {
      return of(this._authFacade.user as User);
    }
    this._authFacade.getCurrentUser();
    return this._authFacade.user$.pipe(
      filter(user => !!user),
      map(user => user as User)
    );
  }
}
